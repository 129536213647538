export function isCorrectSocialNumber(value: string): Date {
  value = value.replace(/[^0-9]+/g, '');
  if (value.length !== 11) {
    return null;
  }

  const yearPart = parseInt(value.substring(0, 2), 10);
  const monthPart = parseInt(value.substring(2, 4), 10);
  const dayPart = parseInt(value.substring(4, 6), 10);

  if (monthPart < 1 || monthPart > 12 || dayPart < 1 || dayPart > 31) {
    return null;
  }

  const checkSum = value.substring(9, 11);

  for (const prefix of ['', '2']) {
    const to_control = prefix + value.substring(0, 9);
    const modulo = Math.floor(97 - (parseInt(to_control, 10) % 97));
    const moduloStr = modulo < 10 ? '0' + modulo : '' + modulo;

    const yearPrefix = prefix === '2' ? 2000 : 1900;
    const dateObj = new Date(yearPrefix + yearPart, monthPart - 1, dayPart, 12, 0, 0);
    const isValidDate = dateObj.getTime() === dateObj.getTime();

    if (!isValidDate) {
      continue;
    }

    if (checkSum === moduloStr) {
      return dateObj;
    }
  }

  return null;
}
